@import "variables";
@import "mixins";

.Menu {
    position: absolute;
    z-index: 10;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #191B1F;
    background-image: url(../img/bg_MENU.png);
    background-size: cover;
    background-position: center;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;


    &[class~=hidden] {
        top: -100vh;
        opacity: 0;
        transition: top ease 500ms, opacity ease 500ms;
    }

    &[class~=active] {
        top: 0;
        opacity: 1;
        transition: top ease 700ms, opacity ease 1000ms;
    }

    .links_container {
        position: absolute;
        top: 29%;
        left: 28%;

        display: flex;
        flex-direction: row;

        a {
            text-decoration: none;
            color: $menuButtonFontColor;
        }

        .main_links {
            @include font($mainFontFamily, $menuFontSize, $menuLetterSpacing);

            margin: 20px 0;

            .menu_links {
                cursor: pointer;
                color: $menuButtonFontColor;
                transition: all ease 400ms;

                &-inactive {
                    color: $menuInactiveFC;
                    transition: all ease 400ms;
                }
            }
        }

        .secondary_links {
            @include font($mainFontFamily, $headingSmallFontSize, $menuLetterSpacing);
            margin-left: 15%;

            align-self: center;

            color: $menuButtonFontColor;

            .event_links {
                margin: 10px 0;
                opacity: 1;
                transition: all ease 400ms;
            }

            .event_links-inactive {
                cursor: default;
                margin: -30px 0;
                opacity: 0;
                transition: all ease 400ms;
            }
        }


    }



}

.menu_btn {
    z-index: 20;
    font-family: $mainFontFamily;
    font-size: $menuButtonFontSize;
    line-height: 145%;


    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    // padding: 17px 14px;

    position: absolute;
    right: 5%;
    top: 2%;

    padding: 1% 1%;
    cursor: pointer;

    word-break: break-all;

    background: #142F66;
    color: $menuButtonFontColor;

    transition: all ease 300ms;

    &[class~=active] {
        z-index: 20;
        color: #142F66;
        background: $menuButtonFontColor;
        transition: all ease 300ms;

    }
}