@import "variables";
@import "mixins";
@import "Home";
@import "Menu";
@import "News";
@import "Events";
@import "Participants";
@import "Media";


html{
  font-size: $HTML_FS;
  font-family: $mainFontFamily;
}

* {
  margin: 0;
  padding: 0;
}


// GLOBAL HEADER STYLE


.Header{
  margin: 0;
  width: 100%;
  height: 100vh;
  text-transform: uppercase;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.pageTitle{
  @include font($mainFontFamily, $pageHeadingFontSize, $menuLetterSpacing);
  color: white;
  cursor: pointer;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.logo {
  width: 20%;
  position: absolute;
  top: 2%;
  left: 9%;
}

.links {
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 50%;
  right: 5%;
  // transform: translateX(50%);

  a{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    height: 3vh;
    width:auto;
    margin: 30%;
  }
}

.Footer{
  position: relative;
  height: 6vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: $secondaryFontFamily;
  font-size: $textFontSize;
  .date{
    color: #2D5096;
    margin-left: 13%;
  }
  .name{
    margin-left: 3%;
    color: white;
  }
  .links{
    flex-direction: row;

    a{
      margin: 0 30%;
    }
    right: 21%;
    transform: translateY(-50%);
  }
}


