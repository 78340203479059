@import "variables";
@import "mixins";

.Events {
    background: #111111;

    >div {
        margin: 0;
        width: 100%;
        height: 100vh;
        // padding-top: 80px;
    }

    .buff {
        width: 100%;
        height: 35vh;
        background-image: url(../img/EVENTS_buff.png);
        background-size: cover;
        background-position: center;
    }

    h2 {
        font-size: $headingSmallFontSize;
        color: white;
        text-align: center;
        padding: 8% 0 5% 0;
    }

    .Header {
        background-image: url(../img/bg_EVENTS.png);
        background-size: cover;
        background-position: center;
    }

    .Calendar {
        background: linear-gradient(257.4deg, rgba(17, 17, 17, 0.44) 29.73%, rgba(26, 28, 30, 0.44) 100%), linear-gradient(180deg, #060608 11.34%, #0B0084 100%);


        .container {
            width: 94%;
            height: 66vh;
            margin: 0 auto;
            display: flex;
        }

        .nearest {
            height: 100%;
            width: 49%;
            position: relative;
        }

        .octagon-white {
            height: 28%;
            aspect-ratio: 1 / 1;
            background-image: url(../img/octagon_white.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            position: absolute;
            top: 0;
            left: 5%;
            transform: translateY(156%) translateX(142%);
            z-index: 10;
        }

        .octagon {
            height: 56%;
            aspect-ratio: 1 / 1;
            background-image: url(../img/octagon.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            font-size: $octagonFS;
            color: white;

            text-align: center;

            .event_date {
                margin-top: 23%;
            }

            .event_name {
                font-size: $smallHeadingsFontSize;
                margin: 10% auto;
                margin-bottom: 0;
                max-width: 60%;
            }

            &[class~=first] {
                position: absolute;
                top: 0;
                left: 5%;
                transform: translateX(46%);
            }

            &[class~=second] {
                position: absolute;
                top: 0;
                left: 5%;
                transform: translateY(80%);
            }

            &[class~=third] {
                position: absolute;
                top: 0;
                left: 5%;
                transform: translateY(80%) translateX(92%);
            }
        }

        .all {
            display: flex;
            flex-direction: column;
            width: 40%;
            color: white;

            .after {
                content: '';
                border-bottom: 1px solid rgba(0, 44, 133, 1);
                flex-grow: 1;
                margin: 0 2%;
                transition: all ease 800ms;
            }

            .title {
                padding-right: 5%;
                display: flex;
                flex-direction: row;

                font-size: $textFontSize;
                text-transform: uppercase;

                margin-bottom: 8%;
            }

            .event_list {
                padding-right: 3%;
                display: flex;
                flex-grow: 1;
                flex-direction: column;

                >div {
                    display: flex;
                    flex-direction: column;
                    font-size: $smallHeadingsFontSize;
                    margin-bottom: 5%;

                    cursor: pointer;
                    overflow: hidden;
                    transition: all ease 800ms;
                    .toggler {
                           
                        width: 5px;
                        height: 5px;
                        border-top: 2px solid black;
                        border-right: 2px solid black;
                        margin-left: 3px;
                        margin-right: 10px;
                        transform: rotate(45deg);
                        transition: all ease 500ms;
                        align-self: center;
                    }

                    &[class~=inactive] {
                        min-height: 4rem;
                        max-height: 4rem;

                        
                    }

                    &[class~=active] {
                        min-height: 14rem;
                        max-height: 14rem;

                        
                        .toggler{
                            transform: rotate(135deg);
                        }
                    }

                    .row {
                        display: flex;
                        flex-direction: row;
                        margin-bottom: 3%;

                    }

                    .reg{
                        
                    }
                }

                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 1vh;
                }

                &::-webkit-scrollbar-track {
                    background: rgba($color: #FFFFFF, $alpha: 0);
                }

                &::-webkit-scrollbar-thumb {
                    width: 5px;
                    border-radius: 100px;
                    background-color: rgba(0, 44, 133, 1);
                }
            }
        }
    }

    .Other {
        height: auto;
        min-height: 100vh;
        background: linear-gradient(180deg, #111111 0%, #00091B 39.37%, rgba(18, 13, 13, 0.4) 52.4%, #000D25 68.02%, #000611 88.33%);
        display: flex;
        flex-direction: column;
        align-items: center;

        .Footer {
            margin-top: 10vh;
        }

        .event {

            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 65%;
            height: 53vh;
            margin: 0;
            padding: 0;

            .line {
                height: 100%;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                border-left: 1px solid rgba(237, 237, 237, 0.4);
            }

            .point {
                width: 1%;
                max-width: 20px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
            }

            .image {
                height: 100%;
                overflow: hidden;

                img {
                    height: 100%;
                    max-width: 20vw;
                }
            }

            .info {
                color: white;
                order: 1;
                justify-content: end;

                .date {
                    font-size: $newDateFS;
                }

                .name {
                    font-size: $octagonFS;
                }

                &[class~=even] {
                    order: -1;
                }
            }
        }
    }
}