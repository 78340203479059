//FONTS
@font-face {
    font-family: "Russo One";
    src: url('../Fonts/RussoOne-Regular.ttf');
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: "Montserrat";
    src: url('../Fonts/Montserrat-VariableFont_wght.ttf');
    font-style: normal;
    font-weight: 400;
}


$mainFontFamily: "Russo One";
$secondaryFontFamily: "Montserrat";

$HTML_FS: 1.11vh;
$blockHeadingsFontSize: 6.4rem;
$pageHeadingFontSize: 7rem;
$uniHeadingFontSize: 6rem;
$textHeadingFontSize: 2.6rem;
$textFontSize: 1.4rem;
$cardFontSize: 1.6rem;
$smallHeadingsFontSize: 1.8rem;
$menuButtonFontSize: 2.1rem;
$octagonFS: 2rem;
$menuFontSize: 6.4rem;
$headingSmallFontSize: 2.6rem;
$newDateFS: 4.3rem;
$subHeading: 3.8rem;

$menuLetterSpacing: 0.06em;

$headingsStroke:1px #000000;

$mainFontColor: black;
$blockHeadingsFontColor:rgba(255, 255, 255, 0);
$menuInactiveFC: rgba(255, 255, 255, 0.15);
$menuButtonFontColor: #FFFFFF;