.main-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.window {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.all-pages-container {
  height: 100%;
  display: flex;
  transition: translate;
  transition-property: transform;
  /* transition-duration: 300ms; */
  transition-timing-function: ease-in-out;
}

.arrow {
  cursor: pointer;
  position: absolute;
  width: 5vh;
  height: 5vh;
}

.left{
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateX(-60%);
}
.right{
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(60%);
}
