@import "variables";
@import "mixins";

.News {
    .Header {
        background-image: url(../img/bg_NEWS.png);
        background-size: cover;
        background-position: center;
    }

    .NewPost {
        position: relative;
        width: auto;
        height: 60vh;
        margin: 10% 8%;
        display: flex;
        flex-direction: row;
        justify-items: stretch;

        img {
            flex: 6;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            background-color: orange;
        }

        .new_text {
            flex: 5;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &[class~=even] {
                order: 11;
                text-align: left;
                margin-left: 30px;

            }

            &[class~=odd] {
                order: -1;
                text-align: right;
                margin-right: 30px;
            }

            color: black;

            h4 {
                font-family: $mainFontFamily;
                font-size: $textHeadingFontSize;
                line-height: 145%;
                margin: 7% 0px;
                text-transform: uppercase;
            }

            p {
                max-height: 40%;
                @include font($secondaryFontFamily, $textFontSize, 0.1em);
                text-transform: uppercase;
                margin-bottom: 7%;
                overflow-y: auto;

                &::-webkit-scrollbar{
                    width: 12px;
                }
                &::-webkit-scrollbar-track{
                    border-radius: 10px;
                    background: #FFFFFF;
                }
                &::-webkit-scrollbar-thumb{
                    border: 4px solid #FFFFFF;
                    border-radius: 10px;
                    background-color: #17a0a0;
                }
            }

            a {
                display: flex;
                flex-direction: column;
                @include font($secondaryFontFamily, $textFontSize, 0.1em);
                line-height: 145%;
                text-decoration-style: dotted;

            }

            span {
                font-family: $mainFontFamily;
                font-size: $newDateFS;
                line-height: 100%;
                background: linear-gradient(180deg, #C3BFDB 0%, #2D5096 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;

                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%) translateY(35%);
            }
        }
    }
}