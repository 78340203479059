@import "variables";
@import "mixins";


.home {
  >div {
    margin: 0;
    width: 100%;
    height: 100vh;
    // padding-top: 80px;
  }

  h1 {
    padding-top: 6vh;
    text-align: center;
  }

  .Header {
    background: #181818;
    background-image: url(../img/bg_top.png);
    background-size: cover;
    background-position: center;

  }

  .rating {
    background-image: url(../img/bg_rating.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;


    .rating-container {
      width: 50vw;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;

      .top {
        display: flex;
        align-items: center;
        gap: 3vw;

        margin-top: 8vh;

        &>div {
          margin: 0;
          padding: 1vw;
        }

        &>div>img {
          margin: 0;
          padding: 0;
          width: 100%;
        }

        .logo-second {
          max-width: 9vw;
          min-width: 9vw;
          aspect-ratio: 1/1;
          border: 1px solid #C0C0C0;
          filter: drop-shadow(0px 0px 5px #C0C0C0);
        }

        .logo-first {
          max-width: 11vw;
          min-width: 11vw;
          aspect-ratio: 1/1;
          border: 1px solid rgba(255, 215, 0, 0.9);
          filter: drop-shadow(0px 0px 10px #FFD700);
        }

        .logo-third {
          max-width: 8vw;
          min-width: 8vw;
          aspect-ratio: 1/1;
          border: 1px solid rgba(205, 127, 50, 0.95);
          filter: drop-shadow(0px 0px 9px #CD7F32);
        }
      }

      .list {
        width: 100%;
        display: flex;
        flex-direction: column;

        .list-item {
          width: 100%;
          height: 7vh;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          border-bottom: 1px solid #3E6694;

          p {
            font-size: $octagonFS;
          }

          .place {
            background: linear-gradient(180deg, #3E6694 0%, #002C85 50.3%, #003AAE 50.31%, #000207 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
          }


        }
      }
    }
  }

  .news {
    display: flex;
    flex-direction: column;

    h1 {
      flex: 0;
    }

    .news-container {
      width: 80%;
      height: 10%;
      margin: 0 auto;
      flex: 1;
    }

    .actual-new {
      position: relative;
      height: 50%;
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .new-text {
      height: 70%;
      position: relative;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;


      .title {
        text-align: center;
        font-size: $menuButtonFontSize;
      }

      .text {
        max-height: 80%;
        text-align: center;
        font-size: $smallHeadingsFontSize;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 12px;
        }

        &::-webkit-scrollbar-track {
          border-radius: 10px;
          background: #FFFFFF;
        }

        &::-webkit-scrollbar-thumb {
          border: 4px solid #FFFFFF;
          border-radius: 10px;
          background-color: #17a0a0;
        }
      }



    }

    .new-btn-1 {
      position: absolute;
      left: 47%;
      bottom: 0;
      transform: translateX(-50%) translateY(-50%);
      float: left;

      img {
        width: 5vh;
      }
    }

    .new-btn-2 {
      position: absolute;
      right: 47%;
      top: 0;
      transform: translateX(50%) translateY(50%) rotate(180deg);

      img {
        width: 5vh;
      }
    }

    .new-img {
      height: 100%;
      flex: 1;
      background-color: #C4C4C4;
      display: flex;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .media {}

  h1 {
    font-family: $mainFontFamily;
    font-weight: 400;
    font-size: $blockHeadingsFontSize;
    line-height: 145%;
    letter-spacing: 0.06em;
    -webkit-text-stroke: $headingsStroke;
    color: $blockHeadingsFontColor;
  }

  .Partners {
    height: 50vh;
    background: #111111;

    h1 {
      -webkit-text-stroke: 1px #FFFFFF;
      text-align: left;
      margin-left: 3vw;
      font-size: $newDateFS;
    }

    .partners-container {
      height: 63%;
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }

  }

  .Footer {
    background: rgba(17, 17, 17, 0.9);
  }
}