
.Scrollable{

    overflow-x: scroll;
    &::-webkit-scrollbar{
        width: 0;
        background: transparent;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;


    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    cursor: pointer;


    div{
        height: 100%;
        aspect-ratio: 1/1;
        margin-right: 3%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p{
            color: white;
            font-size: 15px;
        }
        img{
            max-height: 100%;
            max-width: 100%;
        }
    }

}